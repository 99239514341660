<template>
  <div>
    <div :class='horizontalOrTop'>
      <div v-if='editFormField.titleHtml'
        v-html='editFormField.titleHtml'>
      </div>
      <label
        v-else
        class='uppercase text-sm text-gray-900'
        :class='labelHorizontalLayoutFixedWidth'>
        {{ editFormField.name }}
      </label>
      <edit-form-field-read-only v-if='isReadOnly'
        :value='registrationDetailValue'
        :meta-data='editFormField.metaData'
        :class='horizontalInputCss' />
      <edit-form-field-select v-else-if='isSelect'
        :value='registrationDetailValue'
        :select-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-multi-select v-else-if='isMultiSelect'
        :value='value'
        :select-values.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-text v-else-if='isText'
        :value='registrationDetailValue'
        :input-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
        <edit-form-field-email v-else-if='isEmail'
        :value='registrationDetailValue'
        :input-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-password v-else-if='isPassword'
        :input-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-address v-else-if='isAddress'
        :address-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-phone-number v-else-if='isPhoneNumber'
        :value='registrationDetailValue'
        :phone-number-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-korean-phone-number v-else-if='isKoreanPhoneNumber'
        :value='registrationDetailValue'
        :phone-number-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-date v-else-if='isDate'
        :date-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-birth-date v-else-if='isBirthDate'
        :value='registrationDetailValue'
        :birth-date-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-file-upload v-else-if='isFileUpload'
        :value='registrationDetailValue'
        :file-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-country v-else-if='isCountry'
        :value='registrationDetailValue'
        :country-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-organization-hospital-name v-else-if='isOrganizationHospitalName'
        :value='registrationDetailValue'
        :affiliation-hospital-name-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-vue-editor-abstract v-else-if='isVueEditorAbstract'
        :value='registrationDetailValue'
        :input-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-vue-editor v-else-if='isVueEditor'
        :value='registrationDetailValue'
        :input-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
      <edit-form-field-radio-select v-else-if='isRadioSelect'
        :value='registrationDetailValue'
        :select-value.sync='registrationDetailValue'
        :meta-data='editFormField.metaData' 
        :class='horizontalInputCss' />
    </div>
    <p v-if='helperText' class='mt-2' v-html='helperText'></p>
    <div v-if='showRequirementIndicatorOnTop && needToInputValue'
      class='mt-2 text-red-600 text-xs'
      :class='requirementIndicatorHorizontalLayout'>
      * {{ editFormField.name }} {{ requirementIndicatorHelperText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditFormField',
  components: {
    'edit-form-field-read-only': () => import('@/components/edit-form/EditFormFieldReadOnly.vue'),
    'edit-form-field-select': () => import('@/components/edit-form/EditFormFieldSelect.vue'),
    'edit-form-field-text': () => import('@/components/edit-form/EditFormFieldText.vue'),
    'edit-form-field-email': () => import('@/components/edit-form/EditFormFieldEmail.vue'),
    'edit-form-field-password': () => import('@/components/edit-form/EditFormFieldPassword.vue'),
    'edit-form-field-address': () => import('@/components/edit-form/EditFormFieldAddress.vue'),
    'edit-form-field-phone-number': () => import('@/components/edit-form/EditFormFieldPhoneNumber.vue'),
    'edit-form-field-korean-phone-number': () => import('@/components/edit-form/EditFormFieldKoreanPhoneNumber.vue'),
    'edit-form-field-date': () => import('@/components/edit-form/EditFormFieldDate.vue'),
    'edit-form-field-birth-date': () => import('@/components/edit-form/EditFormFieldBirthDate.vue'),
    'edit-form-field-multi-select': () => import('@/components/edit-form/EditFormFieldMultiSelect.vue'),
    'edit-form-field-file-upload': () => import('@/components/edit-form/EditFormFieldFileUpload.vue'),
    'edit-form-field-country': () => import('@/components/edit-form/EditFormFieldCountry.vue'),
    'edit-form-field-organization-hospital-name': () => import('@/components/edit-form/EditFormFieldOrganizationHospitalName.vue'),
    'edit-form-field-vue-editor-abstract': () => import('@/components/edit-form/EditFormFieldVueEditorAbstract.vue'),
    'edit-form-field-vue-editor': () => import('@/components/edit-form/EditFormFieldVueEditor.vue'),
    'edit-form-field-radio-select': () => import('@/components/edit-form/EditFormFieldRadioSelect.vue'),
  },
  data () {
    return {
      registrationDetailValue: null,
    }
  },
  props: [
    'editFormField',
    'value',
    'isHorizontalLayout',
    'showRequirementIndicatorOnTop',
    'requirementIndicatorHelperText',
  ],
  watch: {
    registrationDetailValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal) {
            this.$emit('update-edit-form-field-value', newVal)
          } else {
            let value = ''
            if (this.isAddress || this.isPhoneNumber || this.isOrganizationHospitalName) {
              value = {}
            } else if (this.isMultiSelect) {
              value = []
            }
            this.$emit('update-edit-form-field-value', value)
          }
        }
      },
      deep: true
    },
    value: {
      handler: function (newVal) {
        if (newVal) {
          this.registrationDetailValue = newVal
        } else {
          this.registrationDetailValue = null
        }
      },
      immediate: true,
    },
  },
  computed: {
    horizontalOrTop () {
      return this.isHorizontalLayout ? 'flex flex-row items-stretch justify-start gap-x-2' : ''
    },
    requirementIndicatorHorizontalLayout () {
      return this.isHorizontalLayout ? '' : 'inline-block ml-1'
    },
    labelHorizontalLayoutFixedWidth () {
      return this.isHorizontalLayout ? 'w-32 lg:w-60 flex-shrink-0 p-2 bg-gray-50' : ''
    },
    horizontalInputCss () {
      return this.isHorizontalLayout ? 'flex-grow' : ''
    },
    needToInputValue () {
      return !this.value ||
             typeof this.value === 'string' && !this.value ||
             typeof this.value === 'object' && !Object.keys(this.value).length ||
             Array.isArray(this.value) && !this.value.length
    },
    isReadOnly () {
      return this.editFormField.inputType === 'read_only'
    },
    isText () {
      return this.editFormField.inputType === 'text'
    },
    isEmail () {
      return this.editFormField.inputType === 'email'
    },
    isPassword () {
      return this.editFormField.inputType === 'password'
    },
    isSelect () {
      return this.editFormField.inputType === 'select'
    },
    isAddress () {
      return this.editFormField.inputType === 'address'
    },
    isPhoneNumber () {
      return this.editFormField.inputType === 'phone_number'
    },
    isKoreanPhoneNumber () {
      return this.editFormField.inputType === 'korean_phone_number'
    },
    isDate () {
      return this.editFormField.inputType === 'date'
    },
    isBirthDate () {
      return this.editFormField.inputType === 'birth_date'
    },
    isMultiSelect () {
      return this.editFormField.inputType === 'multi_select'
    },
    isFileUpload () {
      return this.editFormField.inputType === 'file_upload'
    },
    isCountry () {
      return this.editFormField.inputType === 'country'
    },
    isOrganizationHospitalName () {
      return this.editFormField.inputType === 'organization_hospital_name'
    },
    isVueEditorAbstract () {
      return this.editFormField.inputType === 'vue_editor_abstract'
    },
    isVueEditor () {
      return this.editFormField.inputType === 'vue_editor'
    },
    isRadioSelect () {
      return this.editFormField.inputType === 'radio-select'
    },
    helperText () {
      return this.editFormField.metaData && this.editFormField.metaData.helperText ? this.editFormField.metaData.helperText : ''
    },
  },
  methods: {
  }
}
</script>
